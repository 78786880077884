<template>
  <button v-if="isExist" @click="openOnline(wpsfile.sample_id, name)">
    网页
  </button>
  <button v-if="isExist" @click="wpsOpenReport(wpsfile.sample_id, name)">
    wps
  </button>
  <button v-if="isExistMiddle" @click="openOnline(wpsfile.sample_id, middlename)">
    网页
  </button>
  <button v-if="isExistMiddle" @click="wpsOpenReport(wpsfile.sample_id, middlename)">
    wps
  </button>
</template>

<script>
import { invokewps } from "@/components/sdk.js";
import { baseUrl } from "@/config.js";
export default {
  name: "ViewReport",
  props: {
    wpsfile: {},
  },
  computed: {
    isExist() {
      if (this.wpsfile.status > 5) {
        return true;
      } else {
        return false;
      }
    },
    isExistMiddle() {
      if (this.wpsfile.status > 1 && this.wpsfile.status < 6) {
        return true;
      } else {
        return false;
      }
    },
    name() {
      return this.wpsfile.name.replace("A1/", "");
    },
    middlename() {
      return `${this.wpsfile.name.replace("A1/", "")}M`;
    },
  },
  methods: {
    async openOnline(sample_id, key) {
      try {
        const response = await fetch(
          `${baseUrl}/wps/excelurl/1/0/${sample_id}/${key}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + this.$store.state.auth.token,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          const url = data.url;
          sessionStorage.jurl = url;
          const jump = this.$router.resolve({ name: "excelcontainer" });
          window.open(jump.href, "_blank");
        } else {
          alert("没有提交过,请先提交");
        }
      } catch (error) {
        console.error(error);
      }
    },

    async wpsOpenReport(sample_id, key) {
      try {
        const response = await fetch(
          `${baseUrl}/api/v1/wpsfile/hasreport/${sample_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + this.$store.state.auth.token,
            },
          }
        );
        if (!response.ok) {
          alert("没有提交过,请先提交");
          return;
        }
        const json = await response.json();
        if (json.status < 2) {
          alert("没有提交过,请先提交");
          return;
        }
        const userStr = JSON.stringify(this.$store.state.auth.user);
        const sampleStr = JSON.stringify(this.sample);
        const oafun = {
          openOnline: {
            token: this.$store.state.auth.token,
            user: userStr,
            fname: `A1/${key}`,
            sample: sampleStr,
          },
        };
        const json1 = await invokewps(oafun);
        console.log(json1);
      } catch (e) {
        alert(e.message);
      }
    },
  },
};
</script>

<style scoped>
button {
  display: inline-block;
  border-radius: 2px;
  margin-right: 5px;
}
</style>
