import { Base64 } from "js-base64";
import { oaUrl } from "@/config";

// wps sdk
export const invokewps = async (oafun) => {
  const clientType = "et";
  const oaName = "oa";
  // step0
  const res0 = await fetch("http://127.0.0.1:58890/version", {
    method: "POST",
    body: "",
  });
  // 分支
  if (!res0.ok) {
    // 打开wps server
    const startInfo = {
      name: oaName,
      function: "dispatcher",
      info: JSON.stringify({ funcs: [oafun] }),
      jsPluginsXml: `${oaUrl}/jsplugins.xml`,
    };
    const strData = JSON.stringify(startInfo);
    const baseData = Base64.encode(strData);
    const data = `ksowebstartup${clientType}://${baseData}`;
    // 这个接口无返回内容
    await fetch("http://127.0.0.1:58890/et/runParams", {
      method: "POST",
      body: data,
    });
  } else {
    // step2
    // 向wps serve 发送oa助手代码
    const url = "http://127.0.0.1:58890/transferEcho/runParams";   //使用https 58891 返回状态码为pedding,wps回复https未实现,错了很久
    const cmdId = `js${new Date().getTime()}_0`;
    const funcEx = "const res = dispatcher";
    const infocontent = JSON.stringify({ funcs: [oafun] });
    // 官方写法
    // const cbCode = `var xhr = new XMLHttpRequest();xhr.open('POST','${url}');xhr.send(JSON.stringify({id: '${cmdId}', response: res}));`;
    const cbCode = `fetch("${url}", {method: "POST",body: JSON.stringify({id: '${cmdId}', response: res})});`;
    const infoEx = `${infocontent});${cbCode}void(0`;
    const startInfo = {
      name: oaName,
      function: funcEx,
      info: infoEx,
      jsPluginsXml: `${oaUrl}/jsplugins.xml`,
    };
    const baseData = Base64.encode(JSON.stringify(startInfo));

    // 向wps server发请求
    const wraper = {
      app: clientType,
      id: cmdId,
      data: `ksowebstartup${clientType}://${baseData}`,
    };
    const res = await fetch("http://127.0.0.1:58890/transfer/runParams", {
      method: "POST",
      body: JSON.stringify(wraper),
    });
    const json = await res.json();
    return json;
  }
};
